<template>
  <div id="down">
    <Spin size="large" fix v-show="spinShow"></Spin>
    <ul>
      <li v-for="item in list" :key="item.id">
        <p>{{ item.title }}</p>
      </li>
    </ul>
    <div class="no-results" v-show="+total === 0 && !spinShow">
      <img :src="require('@/assets/qietu/results.png')" alt="">
      <p>暂无下载</p>
    </div>
    <Paging
      :total="total"
      :curPage="page"
      :limit="10"
      :showPage="5"
      @changePager="handlePager"
    ></Paging>
  </div>
</template>

<script>
import Paging from "@/components/paging/index.vue";
export default {
  name: "down",
  components: {
    Paging,
  },
  data() {
    return {
      total: 0,
      page: 1,
      list: [],
      spinShow: false
    };
  },
  created() {
    this.down();
  },
  methods: {
    async down() {
      let obj = {
        pageSize: 10,
        pageNo: this.page,
      };
      this.spinShow = true
      let res = await this.$apis.userServe.dwonList(obj);
      this.total = res.content.page.total;
      this.list = res.content.page.list;
      this.spinShow = false
    },
    handlePager(i) {
      console.log(i);
      this.page = i;
      this.down();
    },
  },
};
</script>

<style lang="scss" scoped>
#down {
  position: relative;
  min-height: 532px;
  background: #ffffff;
  margin-top: 20px;
  ul {
    margin-left: 44px;
    margin-right: 51px;
    li {
      border-bottom: 1px #a0a0a0 dotted;
      width: 100%;
      height: 60px;
      //background: pink;
      p {
        width: 564px;
        height: 60px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 60px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  .no-results {
    margin: 0 auto;
    width: 500px;
    // height: 300px;
    img {
      width: 100%;
    }
    p {
      text-align: center;
      font-size: 16px;
      color: #999999;
    }
  }
}
</style>
